import {
  Button,
  FormControl,
  FormErrorMessage,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Newspaper } from "@phosphor-icons/react/dist/ssr";
import { useEffect, useRef, useState } from "react";
import useBackOnClose from "../lib/useBackOnClose";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";

export default function NewsSubsModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);
  useBackOnClose(isOpen, onClose);
  const handleOnClose = () => {
    onClose();
    window.history.back();
  };

  useEffect(() => {
    const dontShow = localStorage.getItem("dontShowNewsSubsModal");
    if (!dontShow) {
      onOpen();
    }
  }, [onOpen]);

  const toast = useToast();

  const formik = useFormik({
    validateOnChange: false,
    initialValues: { email: "" },
    validationSchema: yup
      .object()
      .shape({ email: yup.string().required("Harus diisi") }),
    onSubmit: (values, { resetForm }) => {
      console.log(values);

      setLoading(true);
      const payload = {
        email: values.email,
      };
      const url = `${process.env.REACT_APP_WEB_API_BASE_URL}api/newsletter/addmember`;
      axios
        .post(url, payload)
        .then((response) => {
          console.log(response.data);
          if (response.data.status === 201) {
            toast({
              title: "Berhasil berlangganan",
              description: response?.data?.message,
              status: "success",
              isClosable: true,
            });
          }
          onClose();
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: "Gagal berlangganan",
            description: error?.response?.data?.message || "Terjadi kesalahan",
            status: "error",
            isClosable: true,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const [loading, setLoading] = useState<boolean>(false);

  const dontShowNewsSubsModal = () => {
    localStorage.setItem("dontShowNewsSubsModal", "true");
    handleOnClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      initialFocusRef={initialRef}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader></ModalHeader>
        <ModalBody>
          <VStack align={"stretch"}>
            <Icon
              mx={"auto"}
              as={Newspaper}
              fontSize={150}
              color={"p.500"}
              weight="fill"
            />
            <Text
              fontWeight={700}
              fontSize={22}
              textAlign={"center"}
              color={"p.500"}
              mb={2}
            >
              Jangan Ketinggalan Info Terbaru!
            </Text>
            <Text fontSize={14} opacity={0.6} mb={2} textAlign={"center"}>
              Tetap terhubung dengan berlangganan Newsletter{" "}
              <span style={{ color: "var(--p500)", fontWeight: 600 }}>
                Kubu.id
              </span>
              . Dapatkan berita terkini, penawaran khusus, dan tips terbaik dari
              kami setiap minggunya!
            </Text>

            <form id="newsSubsForm" onSubmit={formik.handleSubmit}>
              <FormControl isInvalid={!!formik.errors.email}>
                <Input
                  ref={initialRef}
                  name="email"
                  placeholder="Email anda"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                <FormErrorMessage>
                  {formik.errors.email as string}
                </FormErrorMessage>
              </FormControl>
            </form>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <VStack w={"100%"} align={"stretch"}>
            <Button
              type="submit"
              form="newsSubsForm"
              colorScheme="ap"
              className="btn-ap clicky"
              px={6}
              isLoading={loading}
              h={"50px"}
            >
              Subscribe
            </Button>
            <Button
              className="btn-solid clicky"
              onClick={dontShowNewsSubsModal}
            >
              <Text opacity={0.4} fontSize={14}>
                Jangan tampilkan lagi
              </Text>
            </Button>
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
