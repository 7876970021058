import {
  Heading,
  Link,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import Container from "../../components/Container";

export default function TermConditions() {
  return (
    <VStack py={10}>
      <Container maxW={"800px"}>
        <Heading textAlign={"center"} mb={2} fontSize={24} lineHeight={1}>
          SYARAT & KETENTUAN
        </Heading>
        <Heading textAlign={"center"} mb={8} fontSize={24} lineHeight={1}>
          APLIKASI KUBU.ID
        </Heading>
        <Text className="ppText" mb={4}>
          <b>KUBU.ID</b> adalah platform aplikasi berbasis komunitas online
          untuk calon pekerja atau pun yang sudah bekerja. Tujuannya membantu
          mencari lowongan pekerjaan, mencari peluang pekerjaan yang lebih baik,
          mengembangkan Jaringan dan berbagi informasi untuk kamu yang ingin
          terus belajar.
        </Text>
        <Text className="ppText" mb={4}>
          Dengan mengakses dan/atau menggunakan Aplikasi, Anda memahami dan
          setuju untuk tunduk terhadap Syarat dan Ketentuan ini. Mohon untuk
          membaca Syarat dan Ketentuan ini dengan seksama dan secara menyeluruh.
          Dalam hal jika Anda tidak setuju dengan Syarat dan Ketentuan ini, Anda
          berhak untuk tidak mengakses dan/atau tidak menggunakan Aplikasi.
        </Text>
        <Text className="ppText" mb={2}>
          Penggunaan Layanan melibatkan beberapa pihak yang masing-masing
          memiliki hak dan kewajiban yang perlu dipenuhi. Pihak-pihak tersebut
          adalah:
        </Text>

        <OrderedList mb={4} ml={8}>
          <ListItem className="ppText" mb={2}>
            KUBU.ID, sebagai penyedia Layanan.
          </ListItem>
          <ListItem className="ppText">
            Pengguna Layanan (“Pengguna”), yaitu individu atau entitas bisnis
            yang menggunakan Layanan untuk mengakses semua fitur yang terdapat
            pada layanan.
          </ListItem>
        </OrderedList>

        <Heading fontSize={20} my={4}>
          KETENTUAN UMUM PENGGUNA DAN LAYANAN
        </Heading>

        <Text mb={2}>Dengan menggunakan Layanan, Anda menyatakan bahwa:</Text>
        <OrderedList mb={4} ml={8}>
          <ListItem className="ppText" mb={2}>
            Anda setuju untuk terikat dengan S&K yang ditetapkan serta kebijakan
            mengenai pembaruan S&K di kemudian hari. Anda dipersilahkan untuk
            tidak menggunakan dan/atau mengakses Layanan jika tidak setuju untuk
            terikat dengan S&K ini atau pun pembaruannya.
          </ListItem>
          <ListItem className="ppText" mb={2}>
            Apabila Anda belum dewasa menurut ketentuan perundang-undangan yang
            berlaku, wajib mendapatkan bimbingan dan pengawasan orang tua dalam
            menggunakan Layanan.
          </ListItem>
          <ListItem className="ppText">
            Setiap pengguna Layanan setuju untuk tidak menggunakan Layanan
            dengan cara apa pun yang melanggar hak kekayaan intelektual atau hak
            milik orang lain seperti:
          </ListItem>
          <UnorderedList>
            <ListItem className="ppText" mb={2}>
              Mencetak, mengunduh, menggandakan, mengirimkan atau menyalin,
              mereproduksi, mendistribusikan ulang, menerbitkan ulang, atau
              menggunakan informasi pribadi apapun tentang pengguna lain,
              kecuali telah mendapatkan izin tertulis atau dinyatakan lain pada
              S&K;
            </ListItem>
            <ListItem className="ppText" mb={2}>
              Merekayasa balik (reverse engineer) atau mendekompilasi bagian
              mana pun dari Layanan;
            </ListItem>
            <ListItem className="ppText" mb={2}>
              Membuat akun dengan identitas orang lain, menggunakan nama
              perusahaan secara tanpa hak, atau mengunggah data atau informasi
              Layanan yang bersifat privasi secara tanpa hak;
            </ListItem>
            <ListItem className="ppText" mb={2}>
              Mengakses data yang tidak ditujukan untuk Anda atau masuk ke
              server atau akun yang tidak diizinkan untuk Anda akses; dan
            </ListItem>
            <ListItem className="ppText" mb={2}>
              Hal-hal lain yang dilakukan secara tanpa hak dan dapat menimbulkan
              kerugian secara materiil atau pun immateriil bagi pihak KUBU.ID,
              Pengguna dan pihak lain.
            </ListItem>
          </UnorderedList>
          <ListItem className="ppText" mb={2}>
            Pengguna bertanggung jawab atas konten yang mereka unggah ke
            aplikasi KUBU.ID, dan harus memastikan bahwa konten tersebut tidak
            melanggar hak kekayaan intelektual pihak lain, tidak mengandung
            unsur SARA, pornografi, informasi yang tidak pantas. KUBU.ID berhak
            menghapus konten yang kami anggap tidak sesuai dan melanggar syarat
            dan ketentuan.
          </ListItem>
          <ListItem className="ppText" mb={2}>
            Setiap pengguna yang menggunakan Aplikasi KUBU.ID atau situs web
            ataupun link lainnya yang berkaitan dengan KUBU.ID, memahami
            sepenuhnya atas risiko yang ditimbulkan oleh mereka sendiri. Setiap
            pengguna bertanggung jawab atas komunikasi mereka sendiri dan
            bertanggung jawab atas akibat dari aktivitas mereka pada Aplikasi
            KUBU.ID. Karena KUBU.ID tidak mewakili atau menjamin kebenaran,
            keakuratan, atau keandalan komunikasi apapun yang diunggah oleh
            pengguna lain atau mendukung pendapat apapun yang disampaikan oleh
            pengguna. Setiap keterkaitan pengguna pada materi apapun yang
            disampaikan oleh pengguna lain akan menjadi risiko mereka sendiri.
          </ListItem>
          <ListItem className="ppText" mb={2}>
            KUBU.ID berhak untuk memutus akses setiap pengguna dan mencegah
            akses mereka lebih lanjut ke dalam Aplikasi KUBU.ID kapan saja
            dikarenakan melanggar syarat dan ketentuan atau melanggar hukum dan
            peraturan yang berlaku tanpa pemberitahuan dan/atau persetujuan
            apapun.
          </ListItem>
          <ListItem className="ppText" mb={2}>
            Kami tidak bertanggung jawab atas kegagalan akibat kendala mengakses
            situs dan aplikasi Kami, dan metode penggunaan situs dan aplikasi
            yang berada di luar kendali Kami.
          </ListItem>
          <ListItem className="ppText" mb={2}>
            Kami tidak bertanggung jawab atau dapat dipersalahkan atas kegagalan
            saat Anda mengakses atau menggunakan situs dan aplikasi ini.
          </ListItem>
          <ListItem className="ppText" mb={2}>
            Penggunaan dan akses ke situs dan aplikasi ini diatur oleh Syarat &
            Ketentuan serta Kebijakan Privasi Kami. Dengan mengakses atau
            menggunakan Layanan pada situs dan aplikasi KUBU.ID berarti Anda
            telah memahami dan menyetujui serta terikat dan tunduk dengan segala
            Syarat & Ketentuan serta Kebijakan Privasi yang berlaku di situs dan
            aplikasi Kami.
          </ListItem>
          <ListItem className="ppText">
            Kami berhak untuk menutup atau mengubah atau memperbaharui Syarat &
            Ketentuan ini setiap saat tanpa pemberitahuan, dan berhak untuk
            membuat keputusan akhir jika ada ketidakcocokan. Kami tidak
            bertanggung jawab atas kerugian dalam bentuk apa pun yang timbul
            akibat perubahan pada Syarat & Ketentuan.
          </ListItem>
        </OrderedList>

        <Heading fontSize={20} my={4}>
          REGISTRASI
        </Heading>

        <OrderedList ml={8} mb={4}>
          <ListItem className="ppText" mb={2}>
            Untuk mendaftarkan akun pada Layanan, anda harus berusia 15 tahun ke
            atas.
          </ListItem>
          <ListItem className="ppText" mb={2}>
            Data-data yang anda masukkan untuk proses registrasi harus merupakan
            data tepat dan lengkap.
          </ListItem>
          <ListItem className="ppText" mb={2}>
            Anda diperbolehkan mendaftar atas nama pribadi atau perusahaan
            tempat anda bekerja (apabila Anda memiliki hak), dan tidak
            diperbolehkan mendaftar atas nama orang atau perusahaan lain.
          </ListItem>
          <ListItem className="ppText" mb={2}>
            Anda disarankan melengkapi data diri untuk dapat menggunakan
            aplikasi KUBU.ID secara maksimal, terutama untuk fitur yang
            membutuhkan data anda.
          </ListItem>
          <ListItem className="ppText" mb={2}>
            Anda bertanggung jawab untuk menjaga kerahasiaan data yang Anda
            gunakan untuk masuk ke akun Anda.
          </ListItem>
          <ListItem className="ppText" mb={2}>
            Dalam hal Anda lupa kata sandi akun, Anda dapat mengikuti
            langkah-langkah yang terdapat pada Layanan untuk membuat kata sandi
            baru. Apabila terdapat langkah tertentu yang tidak dapat Anda
            penuhi, maka anda tidak akan mendapatkan akses untuk membuat kata
            sandi baru.
          </ListItem>
          <ListItem className="ppText">
            Jika kami memiliki alasan untuk meyakini bahwa akan terjadi
            pelanggaran keamanan atau penyalahgunaan akun Anda oleh pihak yang
            tidak berhak, kami akan meminta Anda untuk mengubah kata sandi Anda
            atau kami dapat menangguhkan akun Anda demi keamanan.
          </ListItem>
        </OrderedList>

        <Heading fontSize={20} my={4}>
          KEKAYAAN INTELEKTUAL
        </Heading>

        <OrderedList ml={8} mb={4}>
          <ListItem className="ppText" mb={2}>
            Aplikasi, situs, akun media sosial, dan semua layanan yang dimiliki
            KUBU.ID adalah milik eksklusif KUBU.ID atau pemberi lisensinya.
          </ListItem>
          <ListItem className="ppText" mb={2}>
            KUBU.ID dan Layanan dilindungi oleh hak cipta, merek dagang, dan hak
            kekayaan intelektual lainnya.
          </ListItem>
          <ListItem className="ppText" mb={2}>
            Anda dapat menggunakan dan menikmati layanan dan tampilan Layanan.
            Anda tidak boleh mereproduksi, memodifikasi, menyalin atau
            mendistribusikan atau menggunakan untuk tujuan komersial hal apa pun
            di Layanan, tanpa mendapatkan izin dari pihak KUBU.ID.
          </ListItem>
          <ListItem className="ppText" mb={2}>
            Kami tidak mengklaim kepemilikan konten Pengguna yang diposting pada
            Layanan dan Anda bebas untuk berbagi konten Anda dengan orang lain
            pada media yang telah Kami sediakan di Layanan. Saat Pengguna
            membagikan, memposting, atau mengunggah konten yang dilindungi oleh
            hak kekayaan intelektual (seperti foto atau video) pada atau
            sehubungan dengan Layanan Kami, Pengguna dengan ini memberikan
            kepada Kami konten non-eksklusif, bebas royalti, dapat dialihkan,
            disublisensikan, lisensi di seluruh dunia untuk menggunakan,
            mendistribusikan, memodifikasi, menjalankan, menyalin, dan
            menampilkan secara publik. Lisensi ini akan berakhir ketika konten
            Pengguna tersebut dihapus dari sistem Kami.
          </ListItem>
          <ListItem className="ppText" mb={2}>
            Segala konten yang terdapat pada Layanan tersimpan dalam bentuk
            elektronik atau dicetakkan dalam satu salinan untuk penggunaan
            pribadi dan non-komersial bagi Pengguna, asalkan Pengguna menjaga
            semua hak kekayaan intelektual yang terdapat didalamnya. Pengguna
            tidak boleh mereproduksi, memodifikasi, menyalin atau
            mendistribusikan atau menggunakan untuk tujuan komersial atau
            terhadap setiap konten pada Layanan tanpa izin tertulis dari Kami.
          </ListItem>
          <ListItem className="ppText" mb={2}>
            Dengan menggunakan Layanan, Pengguna (khususnya Serikat Pekerja dan
            Perusahaan) bersedia memberikan lisensi atas merek dan logo miliknya
            kepada KUBU.ID sepanjang digunakan untuk tujuan-tujuan promosional
            dengan cara yang tidak melanggar hukum yang berlaku di Indonesia.
          </ListItem>
          <ListItem className="ppText">
            KUBU.ID tidak bertanggung jawab atas tindakan yang dilakukan oleh
            pengguna yang melanggar hukum.
          </ListItem>
        </OrderedList>

        <Heading fontSize={20} my={4}>
          PENGGUNAAN INFORMASI PRIBADI
        </Heading>

        <Text textAlign={"justify"} mb={2}>
          Kami dapat menggunakan Informasi Pribadi yang dikumpulkan untuk tujuan
          sebagai berikut maupun untuk tujuan lain yang diizinkan oleh Peraturan
          Perundang-undangan yang Berlaku ("Tujuan"):
        </Text>

        <OrderedList>
          <ListItem className="ppText" mb={2}>
            Jika anda adalah Pengguna, kami dapat menggunakan Informasi Pribadi
            anda untuk mengidentifikasi dan mendaftarkan anda sebagai Pengguna
            dan untuk mengadministrasikan, memverifikasi, menonaktifkan, atau
            mengelola akun anda yang menurut pertimbangan kami diperlukan
            sebelum Penyedia Layanan menyediakan layanan kepada anda atau
            sebelum kami mendaftarkan anda sebagai Pengguna.
          </ListItem>
          <ListItem className="ppText" mb={2}>
            Jika Anda adalah Pengguna, kami dapat menggunakan Informasi Pribadi
            untuk berkomunikasi dengan anda dan mengirimkan anda informasi
            sehubungan dengan penggunaan Aplikasi, memberitahu anda mengenai
            segala pembaruan pada Aplikasi atau perubahan pada layanan yang
            disediakan, untuk mengolah dan menanggapi pertanyaan dan saran yang
            diterima dari anda.
          </ListItem>
          <ListItem className="ppText" mb={2}>
            Kami dapat menggunakan Informasi Pribadi anda untuk melakukan
            pemeliharaan, mengembangkan, menguji, meningkatkan, dan
            mempersonalisasikan Aplikasi untuk memenuhi kebutuhan dan preferensi
            anda sebagai Pengguna.
          </ListItem>
          <ListItem className="ppText" mb={2}>
            Kami dapat menggunakan Informasi Pribadi anda untuk memantau dan
            menganalisis aktivitas, perilaku, dan data demografis pengguna
            termasuk kebiasaan dan penggunaan berbagai layanan yang tersedia di
            Aplikasi.
          </ListItem>
          <ListItem className="ppText" mb={2}>
            Kami dapat menggunakan Infromasi Pribadi anda untuk mengirimkan
            informasi terkait dengan Layanan yang diberikan KUBU.ID, seperti
            iklan, survei, dan informasi tentang penawaran atau promosi khusus.
          </ListItem>
          <ListItem className="ppText" mb={2}>
            Kami akan melindungi privasi pengguna sesuai dengan kebijakan
            privasi kami dan hukum yang berlaku. Sehingga dengan menggunakan
            aplikasi KUBU.ID, pengguna setuju untuk memberikan informasi pribadi
            yang diperlukan untuk fitur-fitur tertentu dalam aplikasi kami.
          </ListItem>
        </OrderedList>

        <Heading fontSize={20} my={4}>
          KEBIJAKAN PRIVASI
        </Heading>

        <OrderedList ml={8} mb={4}>
          <ListItem className="ppText" mb={2}>
            Anda dengan ini memberikan izin yang bebas royalti, tidak dapat
            dicabut kembali, tanpa batas waktu, dapat disublisensikan dan
            berlaku di seluruh dunia untuk menggunakan (termasuk untuk tujuan
            komersial) informasi dan/atau data yang dikumpulkan oleh KUBU.ID
            melalui penggunaan Anda atas Layanan, dengan ketentuan bahwa KUBU.ID
            akan menggunakan atau menjadikan informasi atau data tersebut untuk
            pengembangan KUBU.ID kedepan yang lebih baik.
          </ListItem>
          <ListItem className="ppText">
            Penggunaan dan/atau akses ke Layanan juga akan tunduk pada segala
            aturan yang dituangkan dalam S&K KUBU.ID yang dimuat pada Aplikasi
            dan/atau Situs (“Syarat dan Ketentuan”). Dengan menyetujui Ketentuan
            Penggunaan ini, kami mengasumsikan bahwa Anda telah membaca dan
            menyetujui isi dari Kebijakan Privasi. Kebijakan Privasi dan Syarat
            Ketentuan Penggunaan ini merupakan suatu kesatuan dokumen yang perlu
            disetujui oleh Pelanggan sebelum dapat mendaftarkan diri dan
            menggunakan Layanan KUBU.ID.
          </ListItem>
        </OrderedList>

        <Heading fontSize={20} my={4}>
          PELAYANAN PELANGGAN
        </Heading>

        <OrderedList ml={8} mb={4}>
          <ListItem className="ppText" mb={2}>
            Jika Anda masih memerlukan jawaban atas pertanyaan yang tidak
            terdapat dalam Syarat & Ketentuan ini, Anda dapat menghubungi Kami
            di email{" "}
            <Link href="mailto:kontak@kubu.id" color={"p.500"} fontWeight={700}>
              kontak@kubu.id
            </Link>
          </ListItem>
          <ListItem className="ppText">
            KUBU.ID akan melakukan verifikasi data Pengguna dengan berpedoman
            pada data Pengguna yang tersimpan pada sistem KUBU.ID. Kami berhak
            melakukan penolakan untuk memproses pertanyaan dan keluhan yang Anda
            ajukan terkait Layanan dalam hal data Anda yang diverifikasi tidak
            sesuai dengan data Pengguna yang tersimpan pada sistem kami.
          </ListItem>
        </OrderedList>
      </Container>
    </VStack>
  );
}
