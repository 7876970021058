import { HStack, Icon, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { Star } from "@phosphor-icons/react";
import Container from "../components/Container";
import { StatistikSection } from "../const/types";

type Props = {
  data: StatistikSection;
};

export default function Statistik({ data }: Props) {
  return (
    <VStack w={"100%"} py={8} zIndex={2} mt={-15}>
      <Container bg={"p.500"} p={6} borderRadius={12}>
        <SimpleGrid columns={[2, null, 4]} color={"white"} gap={6}>
          <VStack>
            <HStack>
              <Text fontWeight={800} fontSize={[24, null, 26]} lineHeight={1.2}>
                {data.rating?.value.konten}
              </Text>
              <Icon as={Star} fontSize={[24, null, 26]} weight="fill" />
            </HStack>
            <Text opacity={0.5}>{data.rating?.narasi.konten}</Text>
          </VStack>

          <VStack>
            <Text fontWeight={800} fontSize={[24, null, 26]} lineHeight={1.2}>
              {data.totalLoker?.value.konten}
            </Text>
            <Text opacity={0.5}> {data.totalLoker?.narasi.konten}</Text>
          </VStack>

          <VStack>
            <Text fontWeight={800} fontSize={[24, null, 26]} lineHeight={1.2}>
              {data.unduhan?.value.konten}
            </Text>
            <Text opacity={0.5}>{data.unduhan?.narasi.konten}</Text>
          </VStack>

          <VStack>
            <Text fontWeight={800} fontSize={[24, null, 26]} lineHeight={1.2}>
              {data.penggunaAktif?.value.konten}
            </Text>
            <Text opacity={0.5}>{data.penggunaAktif?.narasi.konten}</Text>
          </VStack>
        </SimpleGrid>
      </Container>
    </VStack>
  );
}
