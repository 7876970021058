import {
  Center,
  Heading,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import Container from "../components/Container";
import { LayananSection } from "../const/types";

type Props = {
  data: LayananSection;
};

export default function Layanan({ data }: Props) {
  return (
    <VStack id="layananSection" py={20}>
      <Container>
        <Heading textAlign={"center"} display={"none"}>
          Layanan
        </Heading>

        <Text
          textAlign={"center"}
          fontSize={[32, null, 34]}
          fontWeight={800}
          mb={12}
        >
          {data.judul.konten}
        </Text>

        <SimpleGrid columns={[1, 2, 3]} gap={4}>
          {data.layananItems?.map((layanan, i) => (
            <VStack key={i} className="layananItem">
              <Center className="layananItemIconBg" bg={"p.300"} mb={4}>
                <Image
                  src={`${process.env.REACT_APP_MEDIA_BASE_URL}${layanan.img.konten}`}
                  className="layananItemIcon"
                  alt={`Icon Layanan ${i}`}
                />
              </Center>

              <Text className="layananItemNarasi" zIndex={2}>
                {layanan.narasi.konten}
              </Text>

              <Image
                src={`${process.env.REACT_APP_MEDIA_BASE_URL}${layanan.img.konten}`}
                className="layananItemIconOverlay"
                alt={`Icon Layanan Overlay${i}`}
              />
            </VStack>
          ))}
        </SimpleGrid>
      </Container>
    </VStack>
  );
}
