import {
  Link as ChakraLink,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { CirclesFour, Eye } from "@phosphor-icons/react";
import { BlogPost } from "../const/types";
import formatDate from "../lib/formatDate";
import formatNumberShort from "../lib/formatNumberShort";

type Props = {
  data: BlogPost;
};

export const PrimaryTrendingBlog = ({ data }: Props) => {
  return (
    <HStack
      as={ChakraLink}
      href={`${process.env.REACT_APP_BLOG_URL}/blog/${data.id}`}
      isExternal
      borderRadius={8}
      p={2}
      transition={"200ms"}
      cursor={"pointer"}
      _hover={{
        bg: "var(--divider)",
      }}
      gap={2}
      h={"100%"}
      w={"100%"}
      align={"stretch"}
    >
      <Image
        alt={`${data.image}`}
        src={`${process.env.REACT_APP_MEDIA_BASE_URL}${data.image}`}
        aspectRatio={4 / 3}
        w={"100%"}
        maxW={"300px"}
        borderRadius={8}
        objectFit={"cover"}
      />

      <VStack h={"100%"} p={2} align={"stretch"} flex={1} gap={0}>
        <HStack justify={"space-between"} mb={2}>
          <HStack>
            <Icon as={CirclesFour} color={"red.400"} fontSize={16} />
            <Text color={"red.400"}>{data.category_name}</Text>
          </HStack>
        </HStack>

        <VStack mt={"auto"}>
          <Text
            fontSize={[20, null, 22]}
            fontWeight={700}
            noOfLines={2}
            lineHeight={1.4}
          >
            {data.title}
          </Text>

          <Text noOfLines={3} mb={4} lineHeight={1.4} opacity={0.5}>
            {data.description}
          </Text>
        </VStack>

        <HStack mt={"auto"} gap={6}>
          <Text>{`${formatDate(data.created_at)}`}</Text>

          <HStack>
            <Icon as={Eye} fontSize={16} />
            <Text opacity={0.5}>{formatNumberShort(data.views * 16)}</Text>
          </HStack>
        </HStack>
      </VStack>
    </HStack>
  );
};
