import {
  Link as ChakraLink,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { BlogPost } from "../const/types";

type Props = {
  data: BlogPost;
};

export const MoreTrendingBlog = ({ data }: Props) => {
  return (
    <HStack
      w={"100%"}
      h={"100%"}
      as={ChakraLink}
      href={`${process.env.REACT_APP_BLOG_URL}/blog/${data.id}`}
      isExternal
      borderRadius={8}
      p={2}
      transition={"200ms"}
      cursor={"pointer"}
      _hover={{
        bg: "var(--divider)",
      }}
      align={"stretch"}
    >
      <Image
        alt={`${data.image}`}
        src={`${process.env.REACT_APP_MEDIA_BASE_URL}${data.image}`}
        aspectRatio={4 / 3}
        w={"100%"}
        maxW={"100px"}
        borderRadius={8}
        objectFit={"cover"}
      />

      <VStack alignSelf={"center"} align={"stretch"} p={2} flex={1} gap={0}>
        <Text
          fontSize={[14, null, 16]}
          fontWeight={600}
          noOfLines={2}
          lineHeight={1.4}
        >
          {data.title}
        </Text>
      </VStack>
    </HStack>
  );
};
