const landingNav = [
  { name: "Beranda", link: "#berandaSection" },
  { name: "Layanan", link: "#layananSection" },
  { name: "Fitur", link: "#fiturSection" },
  { name: "Blog", link: "#blogSection" },
  { name: "Online Course", link: "https://course.kubu.id" },
  { name: "Employer", link: `https://employer.kubu.id/` },
];

export default landingNav;
