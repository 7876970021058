import {
  Box,
  Button,
  Link as ChakraLink,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  Link,
  Text,
  useToast,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import {
  FacebookLogo,
  InstagramLogo,
  Newspaper,
  TwitterLogo,
  YoutubeLogo,
} from "@phosphor-icons/react";
import axios from "axios";
import { useFormik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import Container from "../components/Container";
import { FooterSection } from "../const/types";

type Props = {
  data: FooterSection;
};

export default function Footer({ data }: Props) {
  const today = new Date();

  const toast = useToast();

  const formik = useFormik({
    validateOnChange: false,
    initialValues: { email: "" },
    validationSchema: yup
      .object()
      .shape({ email: yup.string().required("Harus diisi") }),
    onSubmit: (values, { resetForm }) => {
      console.log(values);

      setLoading(true);
      const payload = {
        email: values.email,
      };
      const url = `${process.env.REACT_APP_WEB_API_BASE_URL}api/newsletter/addmember`;
      axios
        .post(url, payload)
        .then((response) => {
          console.log(response.data);
          if (response.data.status === 201) {
            toast({
              title: "Berhasil berlangganan",
              description: response?.data?.message,

              status: "success",
              isClosable: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: "Gagal berlangganan",
            description: error?.response?.data?.message || "Terjadi kesalahan",
            status: "error",
            isClosable: true,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <VStack gap={0} overflow={"hidden"} mt={20}>
      {/* <Box h={"100px"} w={"120%"} bg={"p.800"} borderRadius={"100% 100% 0 0"} /> */}

      <VStack
        bg={"linear-gradient(to bottom left, var(--p800), var(--p700))"}
        w={["200%", "180%", "160%", "140%"]}
        color={"white"}
        pb={8}
        pt={"120px"}
        borderRadius={"50% 50% 0 0"}
      >
        <VStack w={"100vw"}>
          <Container>
            <Wrap spacing={8}>
              <VStack align={"stretch"} flex={"1 1 200px"}>
                <HStack gap={4} mb={4}>
                  <Image src="/logo.png" h={"40px"} alt="Logo Kubu.id" />
                  <Text fontWeight={800} fontSize={[26, null, 28]}>
                    Kubu.id
                  </Text>
                </HStack>

                <Text
                  as={ChakraLink}
                  href={`emailto:${data?.emailKontak}`}
                  mb={4}
                >
                  {data?.emailKontak.konten}
                </Text>

                <HStack>
                  <IconButton
                    aria-label="sosmedButton"
                    as={Link}
                    isExternal
                    href={data?.sosmedLinks?.facebook.konten}
                    icon={
                      <Icon
                        as={FacebookLogo}
                        fontSize={[20, null, 22]}
                        weight="fill"
                        color={"white"}
                      />
                    }
                    variant={"ghost"}
                    className="btn"
                  />

                  <IconButton
                    aria-label="sosmedButton"
                    as={Link}
                    isExternal
                    href={data?.sosmedLinks?.twitter.konten}
                    icon={
                      <Icon
                        as={TwitterLogo}
                        fontSize={[20, null, 22]}
                        weight="fill"
                        color={"white"}
                      />
                    }
                    variant={"ghost"}
                    className="btn"
                  />

                  <IconButton
                    aria-label="sosmedButton"
                    as={Link}
                    isExternal
                    href={data?.sosmedLinks?.instagram.konten}
                    icon={
                      <Icon
                        as={InstagramLogo}
                        fontSize={[20, null, 22]}
                        weight="fill"
                        color={"white"}
                      />
                    }
                    variant={"ghost"}
                    className="btn"
                  />

                  <IconButton
                    aria-label="sosmedButton"
                    as={Link}
                    isExternal
                    href={data?.sosmedLinks?.youtube.konten}
                    icon={
                      <Icon
                        as={YoutubeLogo}
                        fontSize={[20, null, 22]}
                        weight="fill"
                        color={"white"}
                      />
                    }
                    variant={"ghost"}
                    className="btn"
                  />
                </HStack>
              </VStack>

              <VStack align={"stretch"} flex={"1 1 200px"}>
                <HStack>
                  <Icon as={Newspaper} fontSize={20} />
                  <Text fontWeight={600}>Jangan Ketinggalan Info Terbaru!</Text>
                </HStack>
                <Text fontSize={14} opacity={0.6} mb={2}>
                  Jangan lewatkan berita terbaru dan penawaran menarik.
                  Berlangganan newsletter kami sekarang juga!
                </Text>
                <form id="newsSubsFooterForm" onSubmit={formik.handleSubmit}>
                  <FormControl isInvalid={!!formik.errors.email}>
                    <HStack w={"fit-content"}>
                      <Input
                        name="email"
                        placeholder="Email anda"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        _focusVisible={{
                          boxShadow: "0 0 0 1px #ecc94b !important",
                        }}
                      />
                      <Button
                        type="submit"
                        form="newsSubsFooterForm"
                        px={6}
                        isLoading={loading}
                        colorScheme="yellow"
                      >
                        Subscribe
                      </Button>
                    </HStack>
                    <FormErrorMessage>
                      {formik.errors.email as string}
                    </FormErrorMessage>
                  </FormControl>
                </form>
              </VStack>
            </Wrap>

            <Box w={"100%"} h={"1px"} bg={"var(--divider)"} my={8} />

            <Text>
              © {today.getFullYear()} {data?.copyRight?.konten}
            </Text>

            <Text
              as={ChakraLink}
              isExternal
              w={"fit-content"}
              href={process.env.REACT_APP_PRIVACY_POLICY_URL}
            >
              Privacy Terms & Conditions
            </Text>
          </Container>
        </VStack>
      </VStack>
    </VStack>
  );
}
