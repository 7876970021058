import {
  Box,
  Link as ChakraLink,
  HStack,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { CirclesFour, Eye } from "@phosphor-icons/react";
import { BlogPost } from "../const/types";
import formatDate from "../lib/formatDate";
import formatNumberShort from "../lib/formatNumberShort";

type Props = {
  data: BlogPost;
};

export default function BlogPostCard({ data }: Props) {
  return (
    <VStack
      align={"stretch"}
      as={ChakraLink}
      href={`${process.env.REACT_APP_BLOG_URL}/blog/${data.id}`}
      isExternal
      borderRadius={8}
      p={2}
      transition={"200ms"}
      cursor={"pointer"}
      _hover={{
        bg: "var(--divider)",
      }}
      gap={0}
    >
      <Box
        bgImage={`${process.env.REACT_APP_MEDIA_BASE_URL}${data.image}`}
        bgSize={"cover"}
        bgPos={"center"}
        h={"200px"}
        borderRadius={8}
        mb={4}
      />

      <VStack p={2} align={"stretch"} flex={1} gap={0}>
        <HStack justify={"space-between"} mb={2}>
          <HStack opacity={0.5}>
            <Icon as={CirclesFour} fontSize={16} />
            <Text>{data.category_name}</Text>
          </HStack>
        </HStack>

        <Text
          fontSize={[20, null, 22]}
          fontWeight={700}
          noOfLines={2}
          mb={4}
          lineHeight={1.4}
        >
          {data.title}
        </Text>

        <Text noOfLines={2} mb={4} lineHeight={1.4} opacity={0.5}>
          {data.description}
        </Text>

        <HStack justify={"space-between"} mt={"auto"}>
          <HStack>
            <Icon as={Eye} fontSize={16} />
            <Text opacity={0.5}>{formatNumberShort(data.views * 16)}</Text>
          </HStack>

          <Text>{`${formatDate(data.created_at)}`}</Text>
        </HStack>
      </VStack>
    </VStack>
  );
}
