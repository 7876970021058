import { Box, Center, Image, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import LandingNav from "../components/LandingNav";
import Fitur from "../sections/Fitur";
import Hero from "../sections/Hero";
import Layanan from "../sections/Layanan";
// import axios from "axios";
import axios from "axios";
import KubuSpinner from "../components/KubuSpinner";
import { LandingData } from "../const/types";
import Blog from "../sections/Blog";
import Footer from "../sections/Footer";
import Video from "../sections/Video";
import NewsSubsModal from "../components/NewsSubsModal";
import { Loker_Interface } from "../const/lokerInterface";
import Loker from "../sections/Loker";

export default function Landing() {
  const [data, setData] = useState<LandingData | null>(null);
  const [dataLoker, setDataLoker] = useState<Loker_Interface[] | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const url = `${process.env.REACT_APP_WEB_API_BASE_URL}api/landingpage/allcontent`;
    axios
      .get(url)
      .then((response) => {
        if (response.data.status === 200) {
          setData(response.data.data);

          const url = `${process.env.REACT_APP_API_KUBU_PROD}/loker`;
          const request = {
            page: 1,
            limit: 15,
            city: "",
            user_id: 6230,
            order: "DESC",
          };

          axios
            .post(url, request, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              setDataLoker(response.data.data);
            })
            .catch((error) => {
              if (error.response) {
                // Server responded with a status other than 200 range
                console.log("Error response:", error.response.data);
              } else if (error.request) {
                // Request was made but no response received
                console.log("Error request:", error.request);
              } else {
                // Something else caused the error
                console.log("Error message:", error.message);
              }
              console.log("Error config:", error.config);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (!loading && data) {
    return (
      <Box overflowX={"clip"} position={"relative"}>
        <VStack
          gap={0}
          align={"stretch"}
          scrollBehavior={"smooth"}
          bg={"whiteAlpha.800"}
          backdropFilter={"blur(5px)"}
        >
          <NewsSubsModal />

          <LandingNav data={data.nav} />

          {/* KASI BLOG API */}
          <Blog preData={data.blog} />

          <Loker loker={dataLoker} />

          <Hero data={data.hero} dataStatistik={data.statistik} />

          <Layanan data={data.layanan} />

          <Fitur data={data.fitur} />

          <Video data={data.video} />

          <Footer data={data.footer} />
        </VStack>

        <Image
          src={"/images/bgHero.svg"}
          position={"absolute"}
          w={"1000px"}
          zIndex={-1}
          transform={"rotate(20deg)"}
          right={"-620px"}
          top={"-50px"}
        />
      </Box>
    );
  }
  return (
    <Center w={"100%"} h={"100vh"}>
      <KubuSpinner />
    </Center>
  );
}
