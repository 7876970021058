import {
  Box,
  Button,
  Link as ChakraLink,
  Heading,
  HStack,
  Icon,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { GooglePlayLogo } from "@phosphor-icons/react";
import Container from "../components/Container";
import { iconSize } from "../const/sizes";
import { HeroSection, StatistikSection } from "../const/types";
import useScreenHeight from "../lib/useGetScreenHeight";
import Statistik from "./Statistik";

type Props = {
  data: HeroSection;
  dataStatistik: StatistikSection;
};

export default function Hero({ data, dataStatistik }: Props) {
  return (
    <VStack
      id="berandaSection"
      minH={useScreenHeight() < 900 ? "100vh" : ""}
      overflow={"hidden"}
    >
      <Container flex={1} position={"relative"}>
        <SimpleGrid
          columns={[1, null, null, 2]}
          overflow={"hidden"}
          gap={12}
          // border={"1px solid red"}
        >
          <Box py={12} order={[2, null, null, 1]}>
            <HStack mb={4}>
              <Box w={"40px"} h={"2px"} bg={"p.500"} />

              <Text color={"p.500"} fontSize={[12, null, 14]} fontWeight={700}>
                {data.tagline.konten}
              </Text>
            </HStack>

            <Heading
              as={"h1"}
              fontWeight={800}
              fontSize={[32, null, 34]}
              lineHeight={1.4}
              mb={4}
              // maxW={"500px"}
            >
              {data.judul.konten}
            </Heading>

            <Text
              lineHeight={1.8}
              mb={8}
              // maxW={"500px"}
            >
              {data.narasi.konten}
            </Text>

            <Button
              leftIcon={
                <Icon
                  as={GooglePlayLogo}
                  fontSize={iconSize}
                  weight={"duotone"}
                />
              }
              w={"180px"}
              h={"55px"}
              colorScheme="ap"
              pl={"12px !important"}
              className="lg-clicky"
              as={ChakraLink}
              href={data.googlePlayLink.konten}
              isExternal
            >
              Google Play
            </Button>
          </Box>

          <Box
            my={"auto"}
            maxH={"685px"}
            overflow={"clip"}
            order={[1, null, null, 2]}
            className="gambarGila"
          >
            <Box
              w={"100%"}
              h={"max-content"}
              animation={"gambarGila 60s linear infinite"}
            >
              <Image
                w={"100%"}
                src={`${process.env.REACT_APP_MEDIA_BASE_URL}${data.gambarGila.gg1.konten}`}
                alt="Kubu mobile showcase"
              />
            </Box>
          </Box>
        </SimpleGrid>
      </Container>

      <Statistik data={dataStatistik} />
    </VStack>
  );
}
