import { ChakraProvider } from "@chakra-ui/react";
import { globalTheme } from "./chakraTheme/globalTheme";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./pages/Landing";
import "./globalStyle.css";
import MissingPage from "./pages/MissingPage";
import Soon from "./pages/Soon";
import TermConditions from "./pages/PrivacyPolicy/TermConditions";

export const App = () => (
  <ChakraProvider theme={globalTheme}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />

        <Route path="/underconstruction" element={<Soon />} />

        <Route path="/privacy-policy" element={<TermConditions />} />

        <Route path="*" element={<MissingPage />} />
      </Routes>
    </BrowserRouter>
  </ChakraProvider>
);
