import { Spinner, VStack } from "@chakra-ui/react";
import React from "react";

export default function ComponentSpinner(props: any) {
  return (
    <VStack {...props} h={props.h || "200px"} w={"100%"} justify={"center"}>
      <Spinner />
    </VStack>
  );
}
